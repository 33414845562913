import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  Input,
  Link,
  useToast,
} from "@chakra-ui/react"
import { Link as GatsbyLink, navigate } from "gatsby"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import React, { FormEvent, useEffect, useState } from "react"
import { getFirebaseApp } from "../../components/auth/FirebaseContext"
import { FormVStack } from "../../components/Form"
import { FormLayout } from "../../components/layout/FormLayout"
import { MessageDivider } from "../../components/MessageDivider"
import { GoogleLoginButton } from "../../components/auth/GoogleLoginButton"
import { useLocation, WindowLocation } from "@reach/router"

function LoginForm({ onSuccess }: { onSuccess: () => void }) {
  const mixpanel = useMixpanel()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      await getFirebaseApp().auth().signInWithEmailAndPassword(email, password)
      mixpanel.track("Login")
      onSuccess()
    } catch (err) {
      setPassword("")

      if (err.code === "auth/wrong-password")
        setError("Either the email or the password is incorrect.")
      else {
        setError(err.message)
      }
    }

    setLoading(false)
  }

  return (
    // 'autoComplete' attributes from here:
    // https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands
    <form onSubmit={handleSubmit}>
      <FormVStack>
        {error && (
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        )}
        <FormControl id="email">
          <FormLabel>Email address</FormLabel>
          <Input
            required
            value={email}
            onChange={e => setEmail(e.target.value)}
            type="email"
            size="lg"
            autoFocus
            autoComplete="username"
          />
        </FormControl>
        <FormControl id="password" autoComplete="current-password">
          <FormLabel>Password</FormLabel>
          <Input
            required
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
            size="lg"
          />
          <small>
            <Link
              as={GatsbyLink}
              onClick={() => mixpanel.track("Click forgot password")}
              to={`/account/forgot-password?email=${encodeURIComponent(email)}`}
              size="sm"
              opacity={0.6}
              // color="gray.500"
              // textAlign="right"
            >
              Forgot your password?
            </Link>
          </small>
        </FormControl>
        <Button type="submit" colorScheme="blue" size="lg" isLoading={loading}>
          Log In
        </Button>
      </FormVStack>
    </form>
  )
}

function useSignOutUrlAction() {
  const toast = useToast()
  const location = useLocation() as WindowLocation<{ signOut: boolean }>

  useEffect(() => {
    if (location.state?.signOut) {
      getFirebaseApp().auth().signOut()
      navigate("/account/login", { replace: true })
      toast({
        title: "Signed out successfully.",
        isClosable: true,
        position: "top",
      })
    }
  }, [location, toast])
}

export default function Login() {
  useSignOutUrlAction()

  function handleSuccess() {
    navigate("/")
  }

  return (
    <FormLayout title="🚪 Log In">
      <LoginForm onSuccess={handleSuccess} />
      <MessageDivider>or</MessageDivider>
      <GoogleLoginButton onSuccess={handleSuccess} />
    </FormLayout>
  )
}
